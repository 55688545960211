<template>
    <li
        class="item d-flex flex-column"
        v-if="visible"
        :class="{ disabled: disabled || loading }"
        :style="{ display: hide ? 'none !important' : 'block' }"
        ref="orderCheckoutItem"
    >
        <div class="d-flex justify-content-between item__product">
            <div>
                <NuxtLink :to="productUrl" @click="closeBasket">
                    <NuxtImg
                        class="item__product-image"
                        v-if="image"
                        :src="image"
                        loading="lazy"
                        format="webp"
                        width="75"
                        height="75"
                        densities="1x 2x"
                        fit="cover"
                    />
                </NuxtLink>
            </div>
            <div class="flex-column align-self-center flex-fill">
                <NuxtLink :to="productUrl" @click="closeBasket">
                    <div
                        class="equal-small-1 equal-small-1--bold-uppercase item__title"
                    >
                        {{ name }}
                    </div>
                </NuxtLink>
                <div class="equal-small-3 item__product-info">{{ info }}</div>
                <div class="equal-small-2">
                    {{ text }}
                    <div v-if="cut">
                        <span class="item__cut-info">{{ cut?.title }}</span>
                        ca. {{ cut?.weight }}{{ cut?.unit }}
                    </div>
                </div>
            </div>
            <div class="item__button flex-shrink-0" v-if="url" @click="remove">
                <IconsWrapper size="18px" class="item__icon">
                    <IconsDelete />
                </IconsWrapper>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div>
                <FormStepper
                    class="stepper--small"
                    v-model="itemQuantity"
                    :max="numAvailable"
                    :min="1"
                    v-if="quantity"
                    @limit-reached="handleLimitReached"
                    :disabled="loading"
                />
            </div>
            <div class="align-self-center item__price">
                <div
                    v-if="priceInvalid"
                    class="align-self-center equal-small-3 item__price-invalid"
                >
                    {{ basketStore?.basket?.currency }} {{ priceInvalid }}
                </div>
                <div
                    class="align-self-center equal-small-1 equal-small-1--bold-uppercase item__price"
                    :class="{ 'item__price--offer': offer }"
                >
                    {{ basketStore?.basket?.currency }} {{ price }}
                </div>
            </div>
        </div>
        <Message
            v-if="errorMessage"
            :message="errorMessage"
            class="message--danger message--dark-bg item__error"
        />
    </li>
</template>

<script setup>
import { ref, watch } from 'vue';
import debounce from 'lodash.debounce';
import { useOscarClient } from '~/composables/useOscarClient';
import { useBasketStore } from '~/stores/basket';
import {
    useTrackAddToCart,
    useTrackRemoveFromCart,
} from '~/composables/gtm/useEcomTracking';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
    image: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    info: {
        type: String,
    },
    text: {
        type: String,
    },
    quantity: {
        type: Number,
        required: true,
    },
    price: {
        type: String,
        required: true,
    },
    max: {
        type: Number,
        required: false,
    },
    priceInvalid: {
        type: String,
    },
    offer: {
        type: Boolean,
        default: false,
    },
    error: {
        type: String,
        default: '',
    },
    url: {
        type: String,
        default: '',
    },
    loading: {
        type: Boolean,
        default: false,
    },
    cut: {
        type: Object,
        default: {},
    },
    hide: {
        type: Boolean,
        default: false,
    },
    upc: {
        type: String,
        default: '',
    },
    productUrl: {
        type: String,
        default: '',
    },
    item_category: {
        type: String,
        default: '',
    },
    price_excl_tax: {
        type: Number,
        default: 0,
    },
});

const visible = ref(true);
const { locale } = useI18n();
const oscar = useOscarClient(locale.value);
const basketStore = useBasketStore();
const updateError = ref('');
const emit = defineEmits(['update']);
const orderCheckoutItem = ref(null);

const itemQuantity = ref(props.quantity);
const itemUpc = ref(props.upc);
const disabled = ref(false);

function closeBasket() {
    if (document.querySelector('.shopping-cart.show')) {
        document.querySelector('[data-bs-target="#cart"]').click();
    }
}

const errorMessage = computed(() => {
    return props.error
        ? props.error
        : updateError.value
          ? updateError.value
          : '';
});

const url = toRef(props, 'url');

const propQuantity = computed(() => {
    return { quantity: props.quantity, upc: props.upc };
});

watch(propQuantity, (newVal) => {
    // check if upc is the same avoid bug when product gets deleted
    if (newVal.upc === undefined) return;
    if (itemQuantity.value !== newVal.quantity) {
        itemQuantity.value = newVal.quantity;
    }
    if (itemUpc.value !== newVal.upc) {
        itemUpc.value = newVal.upc;
    }
});

watch(itemQuantity, (newVal, oldVal) => {
    if (
        propQuantity.value.upc === undefined ||
        props.upc !== itemUpc.value ||
        props.upc === null ||
        props.upc === ''
    )
        return;
    if (oldVal < newVal && orderCheckoutItem.value.offsetParent !== null) {
        useTrackAddToCart(
            { upc: props.upc, title: props.name },
            {
                incl_tax: props.price / props.quantity,
                original_incl_tax: props.price / props.quantity,
                excl_tax: props.price_excl_tax,
            },
            'basket',
            newVal - oldVal,
            props.item_category,
        );
    } else if (oldVal > newVal) {
        useTrackRemoveFromCart(
            { upc: props.upc, title: props.name },
            {
                incl_tax: props.price / props.quantity,
                original_incl_tax: props.price / props.quantity,
                excl_tax: props.price_excl_tax,
            },
            'basket',
            oldVal - newVal,
            props.item_category,
        );
    }
    const l = basketStore.basket.lines.find((l) => l.url === props.url);
    l.price_incl_tax_excl_discounts = parseFloat(l.unitPrice * newVal).toFixed(
        2,
    );
    basketStore.basket.total_incl_tax = parseFloat(
        basketStore.basket.initialPrice -
            l.unitPrice * l.quantity +
            l.unitPrice * newVal,
    ).toFixed(2);
    updateFunc(newVal);
});

const numAvailable = computed(() => {
    return props.max ? props.max : props.quantity;
});

function handleLimitReached() {
    updateError.value = t('checkout.limitReached');
    setTimeout(() => {
        updateError.value = '';
    }, 4000);
}

const updateFunc = debounce((amount) => {
    disabled.value = true;
    basketStore.loading = true;
    oscar
        .updateLineQuantity(props.url, amount)
        .then((res) => {
            disabled.value = false;
            basketStore.setBasket(res.data);
            emit('update');
            basketStore.loading = false;
        })
        .catch((err) => {
            basketStore.loading = false;
            disabled.value = false;
            emit('update');
            updateError.value = t('checkout.amountUpdateError');
        });
}, 250);

function remove() {
    if (disabled.value || props.loading) return;
    basketStore.loading = true;
    basketStore.hideLine(props.url);
    oscar
        .deleteLine(props.url)
        .then((res) => {
            basketStore.updateBasket();
            useTrackRemoveFromCart(
                { upc: props.upc, title: props.name },
                {
                    incl_tax: props.price / props.quantity,
                    original_incl_tax: props.price / props.quantity,
                    excl_tax: props.price_excl_tax,
                },
                'basket',
                props.quantity,
                props.item_category,
            );
            emit('update');
        })
        .catch((err) => {
            emit('update');
            basketStore.updateBasket();
            updateError.value = t('checkout.productRemoveError');
        });
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.item {
    padding: 20px 0 20px 0;

    @include media-breakpoint-down(640) {
        padding: 15px 0 15px 0;
    }

    &:first-child {
        border-top: 1px solid $color-dark-01;
    }

    border-bottom: 1px solid $color-dark-01;

    &__product {
        margin-bottom: 20px;

        @include media-breakpoint-down(640) {
            margin-bottom: 15px;
        }
    }
    &__title {
        font-size: 16px;
        letter-spacing: unset;
        word-break: break-word;
    }
    &__cut-info {
        color: $color-disabled;
        display: block;
        margin-top: 5px;
    }

    &__product-image {
        height: clamp(60px, 8vw, 75px);
        width: clamp(60px, 8vw, 75px);
        border-radius: 8px;
        margin-right: 20px;

        position: relative;
        display: flex;
        object-fit: cover;
    }

    &__product-info {
        color: $color-disabled;
    }

    &__button {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        margin-left: 20px;

        border-radius: 100%;
        background-color: $color-dark-01;

        cursor: pointer;
    }

    &__icon {
        margin: auto;

        :deep(svg) {
            fill: #999999;
        }
    }

    &__quantity {
        max-width: 140px;
    }

    &__price {
        text-align: right;

        &--offer {
            color: #c62424;
        }
    }

    &__price-invalid {
        text-align: right;
        color: #999999;
        text-decoration: line-through;
    }

    &__error {
        margin-top: 20px;
        text-align: left;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}
</style>
